.cards {
  height: 150px;
  margin-top: 25px;
  border-radius: 10px;
  box-shadow: -7px 24px 37px -8px rgba(0, 0, 0, 0.34);
  font-size: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  margin-top: 20px;
  transition: 0.3s;
}

.cards:hover {
  transform: scale(0.9, 0.9);
}
.title {
  font-size: 46px;
  margin-top: 40px;
  color: #fff;
  text-shadow: 2px 2px 10px #33353b;
}
.subtitle {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: -10px;
  color: #fff;

  text-shadow: 2px 2px 5px #000000;
}

.card1 {
  background: rgb(1, 123, 255);
  background: linear-gradient(
    52deg,
    rgba(1, 123, 255, 1) 0%,
    rgba(29, 233, 253, 1) 100%
  );
}
.card2 {
  background: rgb(138, 170, 254);
  background: linear-gradient(
    50deg,
    rgba(138, 170, 254, 1) 0%,
    rgba(131, 237, 127, 1) 100%
  );
}
.card3 {
  background: rgb(131, 237, 127);
  background: linear-gradient(
    50deg,
    rgba(131, 237, 127, 1) 0%,
    rgba(237, 184, 127, 1) 100%
  );
}
.card4 {
  background: rgb(237, 184, 127);
  background: linear-gradient(
    50deg,
    rgba(237, 184, 127, 1) 0%,
    rgba(246, 71, 125, 1) 100%
  );
}
.icons {
  color: rgba(255, 255, 255, 0.541);
  margin-top: -60px;
  margin-left: 20px;
}
